.svg-map {
    width: 100%;
    height: 100%;
    stroke: #666;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round; }
    .svg-map__location {
      fill: #a1d99b;
      cursor: pointer; }
      .svg-map__location:focus, .svg-map__location:hover {
        fill: #b8e2b3;
        outline: 0; }
      .svg-map__location[aria-checked=true] {
        fill: #44f4538a; }
  
  